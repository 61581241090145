$(document).on('turbolinks:load', function() {
  $('#q_category_id_eq').select2({
    allowClear: true,
    placeholder: I18n.t('labels.choose_category'),
    language: {
      'noResults': () => I18n.t('messages.no_results_found')
    }
  }).on('change', function(event) {
    if (event.target.value) {
      $(event.target).parents('form').submit()
    } else {
      window.location.href = '/pages'
    }
  }).on('select2:unselecting', function() {
    $(this).data('unselecting', true);
  }).on('select2:opening', function(e) {
    if ($(this).data('unselecting')) {
      $(this).removeData('unselecting');
      e.preventDefault();
    }
  });
})
