$(document).on('turbolinks:load', function() {
  if ($('#template-content').length > 0) {
    var pageDescription = $('#page_description')[0]
    var easyMDE = new EasyMDE({element: pageDescription, status: false, maxHeight: '100px'});

    var previousSelectedTemplate;

    easyMDE.codemirror.on('change', function() {
      descriptionContent = easyMDE.value()
      $characterLimitExceededSelector = $(easyMDE.element).siblings('#character-limit-exceeded')

      maxInputLength = 65535
      hasTooLongContent = descriptionContent.length > maxInputLength
      hasValidationError = descriptionContent.length === 0 || hasTooLongContent

      if (hasTooLongContent) {
        $characterLimitExceededSelector.addClass("with-error")

        exceedingCount = (descriptionContent.length - maxInputLength)
        error_translation = I18n.t('activerecord.errors.shared.exceeding_characters_with_attribute',
          { count: exceedingCount, attribute: I18n.t('activerecord.attributes.page.description')}
        )

        $characterLimitExceededSelector.html(error_translation)
      } else {
        $characterLimitExceededSelector.removeClass("with-error")
      }

      $(easyMDE.element).parents('form').find("input[type='submit']").prop('disabled', hasValidationError)
    })

    $('#template-content').select2({
      allowClear: false,
      placeholder: I18n.t('labels.choose_template'),
      language: {
        'noResults': () => I18n.t('messages.no_results_found')
      }
    }).on('focus', function(event) {
        previousSelectedTemplate = event.target.value
      })
      .on('change', function(event) {
        if (confirm(I18n.t('messages.template_change'))) {
          var selectedTemplate = $(event.target).find("option[data-id='" + event.target.value + "']")
          if (selectedTemplate) {
            var description = $(selectedTemplate).data('description')

            $('#page_description').val(description || '')

            easyMDE.value(description || '')
          }
        } else {
          $(this).val(previousSelectedTemplate)
        }
      })
  }
})
