$(document).on('turbolinks:load', function() {
  if ($('#accordion .collapse.show').length === 0) {
    $('#accordion .collapse').collapse();
  }

  if($('.card-header').length > 0) {
    $('.card-header').click(function(e) {
      if($(e.target).hasClass('dropdown-item')) {
        $($(this).data('target')).collapse('show');
      } else {
        if ($(event.target).closest('#comment-menu-btn').length == 0) {
          $($(this).data('target')).collapse('toggle');
        } else {
          $($(this).data('target')).collapse('show');
        }
      }
    });
  }
});
